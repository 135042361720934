import React, { Component } from "react";
import { Link } from 'react-router-dom';
import DashboardBlogContents from "./BlogContent";
import BlogWriting from "./BlogWriting";
import BlogEdit from "./BlogEdit"
import Image_dashboard from "./Image_dashboard";
import ReviewDashboard from "./Review_dashboard";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginstate: false,
            clicked: 0,
            aid: "",
            invoice_id: "",
            collapse: false,
            isMobileMenuOpen: false,
            isMobileView: false
        };
        this.setclickedbec = this.setclickedbec.bind(this);
        this.setclicked = this.setclicked.bind(this);
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        this.checkMobileView();
        window.addEventListener('resize', this.checkMobileView);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkMobileView);
    }

    checkMobileView = () => {
        const isMobileView = window.innerWidth <= 768;
        this.setState({ isMobileView });
    };

    handleToggleMobileMenu = () => {
        this.setState((prevState) => ({
            isMobileMenuOpen: !prevState.isMobileMenuOpen
        }));
    };

    setclicked(num) {
        this.setState({ clicked: num });
    }

    setclickedbec(num, aid) {
        this.setState({ clicked: num });
        this.setState({ aid: aid });
    }

    logout() {
        localStorage.removeItem('accessToken');
        window.location.replace('/');
    }

    render() {
        return (
            <div className="flex flex-row min-h-screen">
                {/* Navigation bar on the left side */}
                <div className="w-1/4 bg-gray-800 text-gray-100 py-4 min-h-full">
                    <h2 className="text-lg font-bold mb-4 ml-2">Dashboard</h2>
                    <ul>
                        <li>
                            <div
                                className="block py-2 px-4 text-sm hover:bg-gray-900"
                                onClick={() => this.setclicked(0)}
                            >
                                Blog Dashboard
                            </div>
                        </li>
                        <li>
                            <div
                                className="block py-2 px-4 text-sm hover:bg-gray-900"
                                onClick={() => this.setclicked(1)}
                            >
                                Photo Dashboard
                            </div>
                        </li>
                        <li>
                            <div
                                className="block py-2 px-4 text-sm hover:bg-gray-900"
                                onClick={() => this.setclicked(4)}
                            >
                                Review Dashboard
                            </div>
                        </li>
                        <li>
                            <Link
                                to="/logout"
                                className="block py-2 px-4 text-sm hover:bg-gray-900"
                            >
                                Logout
                            </Link>
                        </li>
                    </ul>
                </div>
                {/* Big panel on the right side */}
                <div className="w-3/4 p-4 min-h-full">
                    {this.state.clicked == 0 &&
                        <DashboardBlogContents handleAddClick={this.setclickedbec} />
                    }
                    {this.state.clicked == 1 &&
                        <Image_dashboard />
                    }
                    {this.state.clicked == 21 &&
                        <BlogEdit handleBackClick={this.setclicked} aid={this.state.aid} />
                    }
                    {this.state.clicked == 3 &&
                        <BlogWriting handleBackClick={this.setclicked} />
                    }
                    {this.state.clicked == 4 &&
                        <ReviewDashboard />
                    }
                </div>
            </div>
        )
    };
}

export default Dashboard;