import React, { Component } from "react";
import axios from "axios";
import { BASELINE } from "../util";

class BlogBox extends Component {
  componentDidMount() {
    axios.post(BASELINE + "get/articles", {})
      .then((response) => {
        this.create_cards(response.data);
      })
      .catch((error) => {
        alert(error);
      });
  }

  create_cards(data) {
    for (let i = 0; i < 3; i++) {
      var main = document.getElementById("here");

      var box = document.createElement("div");
      box.className = "flex-1 max-w-xs max-h-[400px] shadow-shadow";
      box.addEventListener("click", () => {
        this.props.onBlogBoxClick(data.title[i], data.path[i], data.context[i]);
      });

      var main_img = document.createElement("img");
      main_img.className = "w-full min-h-[249px] max-h-[250px]";
      main_img.src = data.path[i];

      var h2 = document.createElement("h2");
      h2.className = "text-blogTitle text-lg leading-[1.4] lg:text-xl font-bold p-5 mb-[30px]";
      h2.innerText = data.title[i];

      box.appendChild(main_img);
      box.appendChild(h2);
      main.appendChild(box);
    }
  }

  render() {
    return (
      <div id="here">

      </div>
    );
  }
}

export default BlogBox;