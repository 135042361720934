import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import ChatwootWidget from './chatwoot_widget';
import axios from 'axios';

import Header from "./Layout//Header";
import Footer from "./Layout/Footer";

import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Gallery from "./Pages/Gallery";
import Blog from "./Pages/Blog";

import LoginPanel from "./admin/login";
import Dashboard from "./admin/dashboard";
import PrivateRoute from "./PrivateRoute";
const App = () => {
  const [activeSection, setActiveSection] = useState();
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    document.title = 'Promax Painting and Construction';
  }, []);

  const handleNavClick = (sectionId) => {
    setActiveSection(sectionId);
    const section = document.querySelector(`#${activeSection}`);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  axios.defaults.withCredentials = true
  return (
    <>
      <Header onNavClick={handleNavClick} />
      <ChatwootWidget />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/contact" component={Contact} />
        <Route path="/gallery" component={Gallery } />
        <Route path="/blog" component={Blog } />
        <Route path="/admin/login" component={LoginPanel } />
        <PrivateRoute path="/admin/dashboard" component={ Dashboard } />
      </Switch>
      <Footer />
    </>
  );
};

export default App;
