import React from "react";

const Review_remove = ({ id, company, companyAlt, photo, reviewedBy, review, onRemoveReview }) => {
    const handleRemove = () => {
        onRemoveReview(id);
    };

    return (
        <div>
            <div>
                {/* Display review details */}
                <button onClick={handleRemove}>X</button>
            </div>
        </div>
    );
};

export default Review_remove;