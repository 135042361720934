import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";
import { BASELINE, AFTER_LOGIN } from "./util";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    const checkLogin = async () => {
      try {
        const response = await axios.get(BASELINE + 'admin/check/login', {});
        if (response.data.isLoggedIn === true) {
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
      //  console.error(error);
      }
    };
    checkLogin();
  }, []);

  if (isLoggedIn === null) {
    // still checking login status
    return null;
  } else {
    const accessAllowed = canAccessRoute(role, rest.path);

    if (accessAllowed === true) {
      return (
        <Route
          {...rest}
          render={(props) =>
            isLoggedIn ? <Component {...props} /> : <Redirect to="/admin/login" />
          }
        />
      );
    } else {
      // access to the route is not allowed, redirect the user to the main website
      window.location.replace(accessAllowed);
      return null;
    }
  }
};

const canAccessRoute = (role, path) => {
  if (role === "student" && (path === "/dashboard/manager" || path === "/dashboard/teacher" || path === "/view/invoice/:invoiceid")) {
    return AFTER_LOGIN;
  }
  else if (role === "parents" && (path === "/dashboard/manager" || path === "/dashboard/teacher")) {
    return AFTER_LOGIN;
  }
  else if (role === "Teacher" && (path === "/dashboard/manager" || path === "/dashboard/user")) {
    return AFTER_LOGIN;
  }
  return true; // allow access to all other routes
};

export default PrivateRoute;

