import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import { CircularProgress } from '@material-ui/core';  // you can use any spinner you like, this is just an example
import "@splidejs/react-splide/css";
import Service from "../Components/Service";
import axios from "axios";
import { BASELINE } from "../util";
const services = [
  {
    service: "PAINTING / INTERIOR & EXTERIOR",
    serviceDetails:
      "Promax Painting is your trusted partner for all your painting needs in Oregon. Inside, we can enhance your home's ambiance with colors ranging from vibrant to muted, specializing in detailed finishes like kitchen cabinet painting. For exteriors, we provide a meticulous process ensuring a lasting finish. We pride ourselves on delivering impeccable results at a reasonable price, whether rejuvenating your living space or revitalizing your home's exterior.",
    img: "/assets/interiorpainting.png",
    alt: "lounge",
    reverse: false,
    id: "interior-painting"
  },
  {
    service: "Home Revitalization",
    serviceDetails:
      "Promax Service is your reliable ally for all home repair and renovation projects in Oregon. Whether it's a minor repair or a complete home overhaul, our team approaches every task with precision and dedication. We specialize in transforming outdated spaces into modern havens, ensuring that every renovation not only elevates aesthetics but also enhances functionality. Trust Promax Service to breathe new life into your living spaces, delivering top-quality craftsmanship without breaking the bank.",
    img: "/assets/exteriorpainting.png",
    alt: "diningroom",
    reverse: true,
    id: "exterior-painting"
  },
  {
    service: "Floor & Roof Excellence",
    serviceDetails:
      "At Promax Service, we lay the foundation for your dreams, quite literally. Our floor installation services provide the groundwork for stylish and durable living spaces. From hardwood to tiles, our expertise ensures a seamless finish every time. But our dedication doesn't stop at ground level. Elevate your expectations with our roofing solutions, designed to protect, enhance, and withstand Oregon's diverse climates. Trust in Promax Service for a floor-to-ceiling transformation.",
    img: "/assets/commercialpainting.png",
    alt: "house",
    reverse: false,
    id: "commercial-painting"
  },
];

const Home = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(BASELINE + "review/get")
      .then((response) => {
        const { reviews } = response.data;
        setReviews(reviews);
      })
      .catch((error) => {
      //  console.error("Error fetching reviews:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <div className="wrapper bg-image bg-cover bg-no-repeat">
        <section className="contain flex-col gap-8 items-center justify-center py-60">
          <h1 className="text-white text-2xl leading-9 sm:text-4xl md:text-5xl xl:text-6xl font-bold uppercase max-w-2xl text-center sm:leading-[50px] md:leading-[60px] xl:leading-[70px]">
            Painting estimates are always FREE
          </h1>
          <Link to="/contact" className="green-btn">
            Contact Us
          </Link>
        </section>
      </div>

      <div className="wrapper py-8 md:py-16 lg:py-32">
        <section className="contain flex flex-col items-center gap-6">
          <h2 className="heading text-center">Why Promax Painting?</h2>
          <p className="text-darkGray text-md md:text-xl max-w-2xl text-center font-poppins">
            It is a reliable company. We provide our customers with the best,
            perfect and accurate service at a reasonable price.
          </p>

          <div className="flex gap-12 flex-col lg:flex-row lg:gap-6 mt-10">
            <div className="flex flex-col items-center gap-4 rounded-md border-b-[3px] border-b-darkGreen px-6 py-8 flex-1 max-w-md shadow-service">
              <img src="/assets/card1.png" alt="cardimg" className="w-20" />
              <p className="para text-center">
                Our staff consists of seasoned veterans with extensive
                experience in the painting industry, and we take pride in
                utilizing cutting-edge technology and skills.
              </p>
            </div>
            <div className="flex flex-col items-center gap-4 rounded-md border-b-[3px] border-b-darkGreen px-6 py-8 flex-1 max-w-md shadow-service">
              <img src="/assets/card2.png" alt="cardimg" className="w-20" />
              <p className="para text-center">
                We provide reasonable pricing without any hidden costs, ensuring
                transparency throughout the project.
              </p>
            </div>
            <div className="flex flex-col items-center gap-4 rounded-md border-b-[3px] border-b-darkGreen px-6 py-8 flex-1 max-w-md shadow-service">
              <img src="/assets/card3.png" alt="cardimg" className="w-20" />
              <p className="para text-center">
                At Promax Painting, we take punctuality seriously and work
                diligently to adhere to the schedule, always striving to keep
                our promises.
              </p>
            </div>
          </div>
        </section>
      </div>

      <div className="wrapper bg-offWhite py-8 md:py-16 lg:py-32">
        <section className="contain flex-col lg:flex-row items-center gap-10 lg:gap-20">
        <div className="flex flex-col gap-6 min-w-[400px]">
            <h2 className="heading text-center lg:text-start">
              Customer Review
            </h2>
            <p className="para text-center lg:text-start">
              At Promax Painting, our reputation is built on more than just our expertise—it's validated by the voices of our satisfied customers. Our reviews are penned by genuine clients who have experienced our dedication and skill first-hand. With a team comprised of seasoned professionals, we're consistently praised for harnessing the latest technologies and techniques in the painting industry. Delve into our customer reviews to hear the authentic narratives of those who have witnessed the transformative magic of Promax Painting.
            </p>
          </div>

          <div className="max-w-[400px] xl:max-w-none w-full">
            {reviews.length === 0 ? (
              <div className="flex justify-center items-center h-[300px] text-xl font-bold">
                Loading...
              </div>
            ) : (
              <Splide
                hasTrack={false}
                options={{
                  width: "100%",
                  gap: "2rem",
                  perPage: 1,
                  pagination: false,
                  arrows: true,
                  type: "loop",
                  drag: true,
                  perMove: 1,
                }}
                className="center-slides"
                aria-label="..."
              >
                <SplideTrack>
                  {reviews.map((review, index) => (
                    <SplideSlide key={index}>
                      <SliderBox
                        img={BASELINE + review.photo}
                        name={review.reviewedBy}
                        review={review.review}
                      />
                    </SplideSlide>
                  ))}
                </SplideTrack>

                <div className="splide__arrows">
                  <button className="bg-brownGr opacity-100 w-[40px] sm:w-[50px] aspect-square h-auto rounded-full splide__arrow splide__arrow--prev">
                    <svg
                      className="w-6 h-6 invert"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 256 512"
                    >
                      <path d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                    </svg>
                  </button>
                  <button className="bg-brownGr opacity-100 w-[40px] sm:w-[50px] aspect-square h-auto rounded-full splide__arrow splide__arrow--next">
                    <svg
                      className="w-6 h-6 invert"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 256 512"
                    >
                      <path d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                    </svg>
                  </button>
                </div>
              </Splide>
            )}
          </div>

        </section>
      </div>

      <div className="wrapper py-8 md:py-16 lg:py-32">
        <section className="contain flex-col gap-20">
          <h2 className="heading text-center">We can Promise</h2>

          <div className="flex flex-col gap-20">
            {services.map(
              ({ service, serviceDetails, img, alt, reverse, id }, index) => (
                <Service
                  key={index}
                  service={service}
                  serviceDetails={serviceDetails}
                  img={img}
                  alt={alt}
                  reverse={reverse}
                  id={id}
                />
              )
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;

const SliderBox = ({ img, name, review }) => {
  const truncatedReview = review.length > 100 ? review.substring(0, 100) + "..." : review;

  return (
    <div className=" overflow-hidden flex flex-col justify-center items-center gap-4 bg-white rounded-[15px] py-[50px]">
      <img
        src={img}
        className="rounded-full aspect-square w-[160px] object-cover"
        alt=""
      />
      <h4 className="text-blogPara text-2xl font-semibold">{name}</h4>
      <p className="text-[#666666] max-w-[370px] overflow-hidden">{truncatedReview}</p>
    </div>
  );
};