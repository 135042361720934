import React, { Component } from "react";
import axios from "axios";
import { doc } from "prettier";
import { BASELINE } from "../util/index";

class DashboardBlogContents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: false,
      AddSubjectTitle: "",
      SelectedSubject: "",
      SelectedSubject2: "",
      role: "",
      multi_table: [],
      article_data: [] //main data
    };
    this.sendArticleID = this.sendArticleID.bind(this);
    this.handleInputFieldChange = this.handleInputFieldChange.bind(this);
    this.loadarticle = this.loadarticle.bind(this);
    this.sortbyvalue = this.sortbyvalue.bind(this);
    this.nav_multi_table = this.nav_multi_table.bind(this);
    this.change_table = this.change_table.bind(this);
    this.remove_article = this.remove_article.bind(this);
  }

  remove_article(id) {
    var data = {
      aid: id
    }
    var self = this;
    axios.post(BASELINE + "remove/article", data)
      .then(function (response) { 
        self.loadarticle();
      })
      .catch(function (error) {
        alert(error);
      });
  }

  async sendArticleID(num, aid) {
    this.props?.handleAddClick(num, aid);
  }

  handleInputFieldChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  async loadarticle() {
    var self = this;
    axios
      .post(BASELINE + "get/articles", {})
      .then(function (response) { //multi_table
        self.state.article_data = response.data;
        self.sortbyvalue("All");
      })
      .catch(function (error) {
        alert(error);
      });
  }

  async sortbyvalue(sub) {
    this.state.multi_table = [];

    if (sub == "All") {
      for (let i = 0; i < this.state.article_data.aidlst.length; i++) {
        var col = [];
        for (let j = 0; j < 6; j++) {
          if (this.state.article_data.title[i + j]) {
            col.push(parseInt(i + j));
          }
        }
        i = i + 5;

        this.state.multi_table.push(col);
      }
    } else {
      var cnt = 0;
      var st = []; //small table
      for (let i = 0; i < this.state.article_data.aidlst.length; i++) {
        if (this.state.article_data.subject[i] == sub) {
          if (cnt < 6) {
            st.push(parseInt(i));
            cnt += 1;
          } else {
            cnt = 1;
            this.state.multi_table.push(st);
            st = [];
            st.push(parseInt(i));
          }
        }
      }
      if (cnt != 0) {
        this.state.multi_table.push(st);
      }
    }
    this.nav_multi_table()
    this.change_table(0)
  }


  async change_table(row) {
    var j = 0;
    for (let i = 0; i < row; i++) {
      j += 6;
    }

    var mainID = document.getElementById("BlogContentHere");
    mainID.innerHTML = '';

    for (let i = 0; i < this.state.multi_table[row].length; i++) {
      var main = document.createElement("div");
      main.className = "relative";
      main.id = "blog" + (this.state.multi_table[row][i]);

      var a = document.createElement("a");
      a.className = "pb-3 flex items-center bg-white rounded-lg border shadow-xl w-41 border-slate-500";

      var main_img = document.createElement("img");
      main_img.className = "rounded-lg w-48 mx-2 my-2";
      main_img.style.height = "100px";
      main_img.src = this.state.article_data.path[this.state.multi_table[row][i]];

      var div_title = document.createElement("div");
      div_title.className = "flex flex-col justify-between leading-normal pt-8";

      var txt_title = document.createElement("h5");
      txt_title.className = "text-sm font-semibold py-1 tracking-tight text-gray-900";
      txt_title.innerText = this.state.article_data.title[this.state.multi_table[row][i]];

      var div_date = document.createElement("p");
      div_date.className = "text-sm text-gray-700 dark:text-gray-400";
      div_date.innerText = this.state.article_data.date[this.state.multi_table[row][i]];

      div_title.appendChild(txt_title);
      div_title.appendChild(div_date);

      var div_edit = document.createElement("div");
      div_edit.className = "absolute btn flex top-2 right-2";

      var btn_remove = document.createElement("button");
      btn_remove.className = "top-2 bg-red-600 text-white rounded-lg pt";

      var span_remove = document.createElement("span");
      span_remove.className = "text-sm px-4 py-1";
      span_remove.innerText = "Remove";
      span_remove.addEventListener("click", () => {
          this.remove_article(this.state.article_data.aidlst[j + i]);
      });

      btn_remove.appendChild(span_remove);
      div_edit.appendChild(btn_remove);

      var button_edit = document.createElement("button");
      button_edit.className = "ml-[3px] top-2 bg-slate-700 text-white rounded-lg pt";

      var span = document.createElement("span");
      span.className = "text-sm px-4 py-1";
      span.innerText = "Edit Article";
      span.addEventListener("click", () => {
        this.sendArticleID(21, this.state.article_data.aidlst[j + i]);
      });

      button_edit.appendChild(span);
      div_edit.appendChild(button_edit);

      a.appendChild(main_img);
      a.appendChild(div_title);
      a.appendChild(div_edit);
      main.appendChild(a);
      mainID.appendChild(main);
    }
  }

  async nav_multi_table() {
    var main = document.getElementById("multi_table_id_nav");
    main.innerText = "";
    for (let i = 0; i < this.state.multi_table.length; i++) {
      var li = document.createElement("li");
      var a = document.createElement("a");
      a.className = "py-2 px-3 leading-tight font-bold cursor-pointer	";
      a.innerText = i + 1;
      li.append(a);
      main.append(li);
      a.addEventListener("click", () => {
        this.change_table(i);
      });
    }
  }


  componentDidMount() {
    this.loadarticle();
  }

  render() {
    let { AddSubjectTitle, SelectedSubject, SelectedSubject2 } = this.state;
    return (
      <div>
        <div>
          <div className="head bg-slate-700 h-10 rounded-t-lg text-white flex">
            <i className="fa-solid fa-book-open pl-5 pt-3"></i>
            <p className="text-2xl font-semibold ml-5 mt-1">Blog Post</p>
          </div>
          <div className="bg-white rounded-b-lg relative" style={{ height: "800px" }}>
            <div className="posts absolute left-5 top-10 right-6">
              <div className="grid grid-cols-2 gap-7" id="BlogContentHere"></div>

              <div aria-label="Page navigation" className="pt-6">
                <ul className="inline-flex items-center -space-x-px" id="multi_table_id_nav">

                </ul>
              </div>
              <div className="btn">
                <button
                  onClick={() => {
                    this.props?.handleAddClick(3, -1);
                  }}
                  to="/BlogWriting"
                  className="w-full bg-slate-700 text-white p-3 mt-10 rounded-lg block text-center"
                >
                  <span className="text-lg font-bold">Add Article</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardBlogContents;
