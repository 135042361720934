import React, { useState } from "react";

const ReviewForm = ({ onAddReview, onClose }) => {
    const [formData, setFormData] = useState({
        company: "",
        companyAlt: "",
        photo: null,
        reviewedBy: "",
        review: "",
    });
    const [previewImage, setPreviewImage] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prevData) => ({ ...prevData, photo: file }));

        // Preview the image
        const reader = new FileReader();
        reader.onload = () => {
            setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newReview = { ...formData, id: Date.now() };
        onAddReview(newReview);
        setFormData({
          company: "",
          companyAlt: "",
          photo: null,
          reviewedBy: "",
          review: "",
        });
        setPreviewImage(null);      
      };
      

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
            <div className="bg-white rounded-lg p-4 sm:p-6 xl:p-8 relative shadow-lg">
                <button
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                    onClick={onClose}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M13.414 12l4.293 4.293a1 1 0 11-1.414 1.414L12 13.414l-4.293 4.293a1 1 0 11-1.414-1.414L10.586 12 6.293 7.707a1 1 0 111.414-1.414L12 10.586l4.293-4.293a1 1 0 111.414 1.414L13.414 12z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
                <h2 className="text-2xl font-bold mb-4">Add Review</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block font-medium text-gray-700">Company</label>
                        <select
                            name="company"
                            value={formData.company}
                            onChange={handleChange}
                            className="mt-1 px-4 py-2 border border-gray-300 rounded-lg w-full"
                        >
                            <option value="">Select a company</option>
                            <option value="Facebook">Facebook</option>
                            <option value="Yelp">Yelp</option>
                            <option value="google">Google</option>
                            <option value="promaxpdx">Promaxpdx</option>

                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block font-medium text-gray-700">Reviewed By</label>
                        <input
                            type="text"
                            name="reviewedBy"
                            value={formData.reviewedBy}
                            onChange={handleChange}
                            className="mt-1 px-4 py-2 border border-gray-300 rounded-lg w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block font-medium text-gray-700">Review</label>
                        <textarea
                            name="review"
                            value={formData.review}
                            onChange={handleChange}
                            rows={4}
                            className="mt-1 px-4 py-2 border border-gray-300 rounded-lg w-full"
                        ></textarea>
                    </div>
                    <div className="mb-4">
                        <label className="block font-medium text-gray-700">Photo</label>
                        <input
                            type="file"
                            accept="image/*"
                            name="photo"
                            onChange={handleFileChange}
                            className="mt-1"
                        />
                        {previewImage && (
                            <img
                                src={previewImage}
                                alt="Preview"
                                className="mt-2 w-40 rounded-lg"
                            />
                        )}
                    </div>
                    <div className="mt-6">
                        <button
                            type="submit"
                            className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ReviewForm;
