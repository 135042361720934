import React, { useEffect, useState } from "react";
import axios from "axios";
import ImageCard from './ImageCard';

import { BASELINE } from "../util";

const Image_dashboard = () => {
    const [images, setImages] = useState([]);

    const handleRemoveImage = (imageId) => {
        const image = images.find((img) => img.id === imageId);
      
        if (!image) {
          return;
        }
      
        const confirmDelete = window.confirm('Are you sure you want to remove this image?');
      
        if (confirmDelete) {
          var data = {
            url: image.url,
          };
      
          axios
            .post(BASELINE + 'del/image', data)
            .then(function (response) {
              fetchImages();
            })
            .catch(function (error) {
              alert(error);
            });
        }
      };
      

      const handleUploadImage = (event) => {
        const file = event.target.files[0];
      
        // Check if the file type is JPG or PNG
        if (file && file.type !== 'image/jpeg' && file.type !== 'image/png') {
          alert('Only JPG and PNG images are allowed.');
          return;
        }
      
        const formData = new FormData();
        formData.append('image', file);
      
        // Make a POST request to the server to insert the image
        axios
          .post(BASELINE + 'insert/image', formData)
          .then(function (response) {
            // Image insertion successful, fetch the updated images
            fetchImages();
          })
          .catch(function (error) {
            alert(error);
          });
      };
      
      
      

  const fetchImages = () => {
    axios
      .get(BASELINE + "get/images")
      .then(function (response) {
        const imagesData = response.data.imagesPaths.map((url, index) => ({
          id: index + 1,
          title: `Image ${index + 1}`,
          url: url,
        }));
        setImages(imagesData);
      })
      .catch(function (error) {
        alert(error);
      });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  return (
    <div className="p-4">
      <div className="mb-4">
        <input
          type="file"
          accept="image/*"
          onChange={handleUploadImage}
          className="hidden"
          id="upload-input"
        />
        <label
          htmlFor="upload-input"
          className="px-4 py-2 bg-blue-500 text-white rounded cursor-pointer"
        >
          Upload Image
        </label>
      </div>
      <div className="grid grid-cols-3 gap-4">
        {images.map((image) => (
          <ImageCard
            key={image.id}
            image={image}
            onRemove={handleRemoveImage}
          />
        ))}
      </div>
    </div>
  );
};

export default Image_dashboard;
