
export const CLIENT_URL = "https://promaxpdx.com:8082/files";
export const BASELINE = "https://promaxpdx.com:8082/";
export const AFTER_LOGIN = "httpss://promaxpdx.com";


/*
 export const CLIENT_URL = "http://localhost:8080/files";
 export const BASELINE = "http://localhost:8080/";
 export const AFTER_LOGIN = "http://localhost:3000";

*/