import React, { useEffect, useState } from "react";
import axios from "axios";

import { BASELINE } from "../util";

const Gallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    function callApi() {
      axios.get(BASELINE + "get/images", {})
        .then(function (response) {
        //  console.log(response.data.imagesPaths);

          const imgs = []
          for (var i = 0; i < response.data.imagesPaths.length; i++) {
            imgs.push(<img key={i} src={response.data.imagesPaths[i]} />);
          }
        //  console.log(imgs);
          setImages(imgs);
        })
        .catch(function (error) {
          alert(error);
        });

    }

    callApi();
  }, []);

  return (
    <>
      <div className="wrapper gallery-bg relative bg-cover bg-no-repeat bg-center">
        <div className="absolute inset-0 bg-bannerGradient -z-10"></div>
        <section className="contain flex-col gap-6 justify-center h-80 sm:h-96 items-center">
          <h1 className="banner-heading">Gallery</h1>
        </section>
      </div>

      <div className="wrapper py-16">
        <section className="contain flex-col gap-8">
          <div id="display_img" className="shadow-gallery p-0 sm:p-6 lg:p-12 grid gap-2 grid-cols-2 grid-rows-5 sm:grid-cols-3 md:grid-rows-4 lg:grid-cols-4 lg:grid-rows-3 place-items-center sm:gap-4 md:gap-6 lg:gap-8">
            {images}
          </div>
        </section>
      </div>
    </>
  );
};

export default Gallery;
