import Top from "../Components/GoTop";

const Contact = () => {
  const openForm = () => {
    const url = 'https://docs.google.com/forms/d/e/1FAIpQLSeawAVPpjuNmYL44o_UiGF-jrgfSJoMBsg1zH1Q0_RuG8DgIw/viewform?usp=sf_link';
    window.open(url, '_blank');
  };
  return (
    <div>
      <Top />

      <div className="wrapper contact-bg relative bg-cover bg-no-repeat bg-center">
        <div className="absolute inset-0 bg-bannerGradient -z-10"></div>
        <section className="contain flex-col gap-3 sm:gap-6 justify-center h-80 sm:h-96 items-center">
          <h1 className="banner-heading">Contact Us</h1>
          <p className="text-sm sm:text-lg text-center text-white max-w-4xl font-poppins">
            At Promax Service & Oregon House Fix, we specialize in comprehensive home and commercial solutions.
            From landscaping and flooring to meticulous painting and power washing, our expert team guarantees top-tier results.
            Dedicated to exemplary workmanship and unmatched customer service, we're Oregon's go-to for renovations and repairs.
            Contact us today.
          </p>
        </section>
      </div>

      <div className="wrapper py-8">
        <section className="contain lg:flex-row flex-col gap-8 justify-center items-stretch">
          <iframe
            className="w-full aspect-square sm:aspect-video lg:aspect-auto lg:w-1/2 rounded-lg"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11174.930509661392!2d-122.833061!3d45.5557039!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54950f76feb29b71%3A0x96e03963a000dd1a!2sMMT%20Prep!5e0!3m2!1sko!2sus!4v1693803549270!5m2!1sko!2sus"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>

          <div className="bg-formBg w-full lg:w-1/2 flex-1 flex flex-col gap-12 items-center p-8 md:p-12 rounded-lg">
            <div className="flex-1 w-full flex flex-col gap-8">
              <div className="flex flex-col gap-3 sm:gap-4">
                <h2 className="input-label">Email</h2>
                <p>promaxpdx@gmail.com</p>
              </div>

              <div className="flex flex-col gap-3 sm:gap-4">
                <h2 className="input-label">Phone Number</h2>
                <p>971-278-9421</p>
              </div>

              <div className="flex flex-col gap-3 sm:gap-4">
                <h2 className="input-label">Address</h2>
                <p>15160 NW Laidlaw Rd Suite 116</p>
              </div>

              <div className="flex flex-col gap-3 sm:gap-4">
                <h2 className="input-label">Submit the google Form</h2>
                <button className="bg-darkGreen text-white font-medium py-4 px-10 rounded-lg"
                  onClick={openForm}
                >
                  Google Form
                </button>
              </div>
            </div>

          </div>
        </section>
      </div>
    </div>
  );
};

export default Contact;
