import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

const Pagination = ({ maxPage, selectedPage, onPageChange }) => {
  const [startPage, setStartPage] = useState(
    selectedPage > 4 ? selectedPage - 3 : 1
  );

  const handlePageClick = (pageNumber) => {
    onPageChange(pageNumber);
  };

  const handleNextClick = () => {
    const newStartPage = startPage + 4;
    const newSelectedPage = selectedPage + 1;
    if (newStartPage <= maxPage) {
      setStartPage(newStartPage);
      onPageChange(selectedPage + 1);
    }
  };

  const pageNumbers = [];
  for (let i = startPage; i < startPage + 4 && i <= maxPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      {startPage > 1 && (
        <div
          className="w-10 h-10 sm:w-14 sm:h-14 font-poppins shadow-pagination text-base sm:text-lg font-medium grid place-items-center rounded-lg cursor-pointer duration-100 hover:bg-gray-200"
          onClick={() => setStartPage(startPage - 4)}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
      )}
      {pageNumbers.map((pageNumber) => (
        <div
          key={pageNumber}
          className={`w-10 h-10 sm:w-14 sm:h-14 font-poppins shadow-pagination text-base sm:text-lg font-medium grid place-items-center rounded-lg cursor-pointer duration-100 ${
            pageNumber === selectedPage
              ? "bg-bannerGradient text-white"
              : "hover:bg-gray-200"
          }`}
          onClick={() => handlePageClick(pageNumber)}
        >
          {pageNumber}
        </div>
      ))}
      {startPage + 4 <= maxPage && (
        <div
          className="w-10 h-10 sm:w-14 sm:h-14 font-poppins shadow-pagination text-base sm:text-lg font-medium grid place-items-center rounded-lg cursor-pointer duration-100 hover:bg-gray-200"
          onClick={handleNextClick}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      )}
    </>
  );
};

export default Pagination;