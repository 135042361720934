import React, { useState } from 'react';
import axios from "axios";
import { BASELINE, AFTER_LOGIN } from "../util";
function LoginPanel() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    check_login(username, password); // Call the check_login function with the username and password
  };

  const check_login = (username, password) => {
    var data = {
        username : username,
        password : password
    }
    axios.post(BASELINE + "admin/login", data)
    .then(function (response) {
        alert(response.data.message);
        if(response.data.message === "Login") {
            window.location.replace(AFTER_LOGIN + '/admin/dashboard'); // Redirect the user to the dashboard page
        }
    })
    .catch(function (error) {
      alert(error);
    });
  };

  return (
    <div className="bg-gray-100 flex justify-center items-center h-screen">
      <form className="bg-white p-10 rounded shadow-md" onSubmit={handleSubmit}>
        <h1 className="text-2xl font-medium mb-5">Admin Login</h1>
        <div className="mb-5">
          <label htmlFor="username" className="block text-gray-700 font-medium mb-2">Username</label>
          <input
            type="text"
            id="username"
            className="border rounded py-2 px-3 w-full"
            value={username}
            onChange={handleUsernameChange}
          />
        </div>
        <div className="mb-5">
          <label htmlFor="password" className="block text-gray-700 font-medium mb-2">Password</label>
          <input
            type="password"
            id="password"
            className="border rounded py-2 px-3 w-full"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white rounded py-2 px-4 hover:bg-blue-700"
        >
          Login
        </button>
      </form>
    </div>
  );
}

export default LoginPanel;