import React from 'react';

const ImageCard = ({ image, onRemove }) => {
  return (
    <div className="relative w-full bg-white shadow-md rounded-lg overflow-hidden">
      <img src={image.url} alt={image.title} className="w-full h-auto" />
      <div className="absolute top-0 right-0 m-2">
        <button
          className="text-red-500 hover:text-red-700 focus:outline-none"
          onClick={() => onRemove(image.id)}
        >
          X
        </button>
      </div>
      <div className="p-2">
        <p className="text-sm text-gray-700">{image.title}</p>
      </div>
    </div>
  );
};

export default ImageCard;
