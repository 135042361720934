import React, { Component } from "react";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

import BlogBox from "../Components/BlogBox";
import Pagination from "../Components/Pagination";
import { BASELINE } from "../util";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: 0,
      selectedPage: 1,
      title: "this is the title",
      title_img: '',
      context: "",
      date: ''
    };

    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleBlogBoxClick = this.handleBlogBoxClick.bind(this);
  }

  
  handleBlogBoxClick = (title, title_img, context) => {
    this.setState({
      title,
      title_img,
      context,
    });
  };

  handlePageChange = (page) => {
    this.setState({
      selectedPage: page,
    });
    this.state.selectedPage = page;

    var data = {
      aid: page
    }

    var self = this;
    axios.post(BASELINE + "get/article", data)
      .then(function (response) {
        self.setState({ title: response.data.title });
        self.setState({ title_img: response.data.img });
        self.setState({ context: response.data.context });
        self.setState({ date: response.data.date });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  componentDidMount() {
    var self = this;
    axios
      .post(BASELINE + "get/articles", {})
      .then(function (response) { //multi_table
        const size = parseInt(response.data.aidlst.length);
        self.setState({ size: size });
        self.state.size = size;
      })
      .catch(function (error) {
        alert(error);
      });

    axios.post(BASELINE + "get/article", { aid: 1 })
      .then(function (response) {
        self.setState({ title: response.data.title });
        self.setState({ title_img: response.data.img });
        self.setState({ context: response.data.context });
        self.setState({ date: response.data.date });
      })
      .catch(function (error) {
        alert(error);
      });
  }

  render() {
    return (
      <div>
        <div className="wrapper blog-bg relative bg-cover bg-no-repeat bg-center">
          <div className="absolute inset-0 bg-bannerGradient -z-10"></div>
          <section className="contain flex-col gap-6 justify-center h-80 sm:h-96 items-center">
            <h1 className="banner-heading">Blog</h1>
          </section>
        </div>

        <div className="wrapper mt-16">
          <section className="contain flex-col lg:flex-row lg:gap-8 gap-12">
            <div className="flex-1 flex flex-col gap-10">
              <div>
                <h2 className="text-blogTitle text-2xl leading-7 md:text-4xl font-bold md:leading-[50px]">
                  {this.state.title}
                </h2>
                <div className="text-blogPara text-base sm:text-lg pt-3">
                  {this.state.date}
                </div>
              </div>

              <div>
                <img
                  src={this.state.title_img}
                  alt="interior design"
                  className="pb-8"
                />
                < CKEditor
                  disabled={true}
                  editor={DecoupledEditor}
                  data={this.state.context}
                  onReady={(editor) => { }}
                />
              </div>
            </div>

            <div className="w-full lg:w-80 flex flex-col gap-6">
              <div className="lg:mx-0 mx-auto">
                <h2 className="text-blogTitle text-2xl font-bold">
                  Recent Event
                </h2>
                <div className="w-24 h-0.5 bg-blogTitle mx-auto lg:mx-0"></div>
              </div>

              <div className="flex items-center flex-col md:flex-row lg:flex-col gap-10">
                <BlogBox
                  title={this.state.title}
                  context={this.state.context}
                  title_img={this.state.title_img}
                  onBlogBoxClick={this.handleBlogBoxClick}
                />
              </div>
            </div>
          </section>
        </div>

        <div className="flex gap-4 justify-center my-28">
          {this.state.size > 0 && (
            <Pagination maxPage={this.state.size} selectedPage={this.state.selectedPage} onPageChange={this.handlePageChange} />
          )}
        </div>
      </div>
    );
  }
}

export default Blog;
